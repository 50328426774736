import React, { useEffect, useRef, useState } from "react";
import {
  appstoreIcon,
  appstoreSelectedIcon,
  backIcon,
  bannerImage,
  dummyAppIcon,
  playstoreIcon,
  playstoreSelectedIcon,
  uploadIcon,
  appIconUploadErrorImage,
  removeAppIcon,
} from "../../assets";
import "./RegisterAppScreen.scss";
import {
  CustomCheckboxCard,
  InputField,
  ErrorPopup,
  SuccessPopup,
  ProcessingDialog,
} from "../../components";
import {
  getLineOfBusinessApiFailed,
  getLineOfBusinessApiInitiated,
  getLineOfBusinessApiSucceed,
  postRegisterApiFailed,
  postRegisterApiInitiated,
  postRegisterApiSucceed,
} from "../../stateManagement/actions/registerAppActions";
import { useDispatch } from "react-redux";
import { AxiosError, AxiosResponse } from "axios";
import { ErrorResponse } from "../../interfaces/common/ErrorResponse";
import { initiateApiCall } from "../../api";
import { useLocation, useNavigate } from "react-router-dom";
import {
  checkFieldEmpty,
  checkIconSize,
  clearCacheAndCookies,
  isValidEmail,
  isValidURL,
  uploadAppIcon,
  updateAppCache,
} from "../../utils/Utils";
import { ApiResponse } from "../appDetailsScreen/AppDetailsScreen";
import CapsuleComponent from "../../components/capsuleComponent/CapsuleComponent";
import { businessLines } from "./dummyBusinessLine";
import { useSelector } from "react-redux";
import { shallowEqual } from "react-redux";
import {
  getAppDetailsApiFailed,
  getAppDetailsApiInitiated,
  getAppDetailsApiSucceed,
} from "../../stateManagement";
import BusinessTypeCapsuleComponent from "./BusinessTypeCapsuleComponent";
import { useAuth } from "../../routes/useAuth";
import { appDetails } from "../appDetailsScreen/AppDetailsScreen";

interface RegisterAppApiResponse {
  code: number;
  message: string;
  result: Result;
  error: string;
}

interface LineOfBusinessApiResponse {
  code: number;
  message: string;
  result: LineOfBusiness[];
  error: string;
}

interface Result {
  "APP-ID": string;
  "APP-SECRET": string;
  "package-name": string;
  "platform-name": string;
  "app-name": string;
  "app-version": string;
  "published-app-name": string;
  "publish-url": string;
  category: string;
  "app-description": string;
  "minimum-platform-version": string;
  "build-version": string;
  developer: string;
  "business-type": string;
  "privacy-url": string;
  "owner-email": string;
  "contact-info": string;
  "support-email": string;
  "store-data": any;
  deleted: boolean;
  "platform-registered": boolean;
  "registered-by": string;
  "registrar-email": string;
  "created-date": string;
  "updated-date": string;
  "business-lines": number[];
  "business-types": number[];
  "lb-app-mapping": [
    {
      lbId: 0;
      createdDate: "string";
      lastUpdatedDate: "string";
      version: "string";
    }
  ];
  "app-icon": any;
}

interface Field {
  value: any;
  validationError: string;
  required: boolean;
}

type RegisterAppData = {
  [key: string]: Field;
};

const initialAppData: RegisterAppData = {
  "platform-name": { value: "android", validationError: "", required: true },
  "app-name": { value: "", validationError: "", required: true },
  "package-name": { value: "", validationError: "", required: true },
  "app-version": { value: "", validationError: "", required: true },
  "published-app-name": { value: "", validationError: "", required: false },
  "publish-url": { value: "", validationError: "", required: false },
  category: { value: "", validationError: "", required: false },
  "app-description": { value: "", validationError: "", required: false },
  "minimum-platform-version": {
    value: "",
    validationError: "",
    required: false,
  },
  "build-version": { value: "", validationError: "", required: false },
  developer: { value: "", validationError: "", required: false },
  "registered-by": { value: "", validationError: "", required: true },
  "registrar-email": { value: "", validationError: "", required: true },
  "privacy-url": { value: "", validationError: "", required: true },
  "owner-email": { value: "", validationError: "", required: true },
  "support-email": { value: "", validationError: "", required: false },
  "business-lines": { value: [], validationError: "", required: false },
  "business-types": { value: [], validationError: "", required: false },
  "app-icon": { value: null, validationError: "", required: false },
};

export interface LineOfBusiness {
  id: number;
  "line-of-business": string;
  "business-direction": string;
}

export interface BusinessTypes {
  "business-type-id": number;
  "business-type-name": string;
  "business-type-desc": string;
}

const RegisterAppScreen = () => {
  const { setAuthToken }: any = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state, pathname } = useLocation();

  const businessTypeArray = [
    {
      "business-type-id": 1,
      "business-type-name": "B2B",
      "business-type-desc": "",
    },
    {
      "business-type-id": 2,
      "business-type-name": "B2C",
      "business-type-desc": "",
    },
    {
      "business-type-id": 3,
      "business-type-name": "B2E",
      "business-type-desc": "",
    },
  ];

  const [name, setName] = useState<string>("");

  const [appDetails, setAppDetails] = useState<appDetails>();

  const handleErrorAppDetails = (error: ErrorResponse) => {
    setErrorPopupData({
      boldMessage: error.error,
      message: error.message,
    });
    setIsErrorPopupVisible(true);
  };

  const handleSuccessResponse = (response: appDetails) => {
    setAppDetails(response);
  };

  const { apiCallInitiated } = useSelector(
    (state: any) => state.registerAppReducers,
    shallowEqual
  );

  const [errorPopupData, setErrorPopupData] = useState({
    boldMessage: "",
    message: "",
  });
  const [isSuccessPopupVisible, setIsSuccessPopupVisible] = useState(false);
  const [isErrorPopupVisible, setIsErrorPopupVisible] = useState(false);
  const [successPopupData, setSuccessPopupData] = useState({
    boldMessage: "",
    message: "",
  });
  const [isProcessing, setIsProcessing] = useState(false);
  const [selectedLB, setSelectedLB] = useState<LineOfBusiness[]>([]);
  const [allLBs, setAllLBs] = useState<LineOfBusiness[]>(businessLines);
  const [businessTypes, setBusinessTypes] =
    useState<BusinessTypes[]>(businessTypeArray);
  const [selectedBusinessType, setSelectedBusinessType] = useState<
    BusinessTypes[]
  >([]);

  const handleLbs = (lb: LineOfBusiness, operation = "add") => {
    if (operation === "remove") {
      setSelectedLB(
        selectedLB.filter((selected: LineOfBusiness) => selected !== lb)
      );
      setAllLBs([...allLBs, lb]);
      return;
    } else if (operation === "clearAll") {
      setSelectedLB([]);
      setAllLBs(businessLines);
      return;
    }
    setSelectedLB([...selectedLB, lb]);
    setAllLBs(allLBs.filter((selected: LineOfBusiness) => selected !== lb));
  };

  const handleBusinessTypes = (
    businessType: BusinessTypes,
    operation = "add"
  ) => {
    if (operation === "remove") {
      setSelectedBusinessType(
        selectedBusinessType.filter(
          (selected: BusinessTypes) => selected !== businessType
        )
      );
      setBusinessTypes([...businessTypes, businessType]);
      return;
    } else if (operation === "clearAll") {
      setSelectedBusinessType([]);
      setBusinessTypes(businessTypeArray);
      return;
    }
    setSelectedBusinessType([...selectedBusinessType, businessType]);
    setBusinessTypes(
      businessTypes.filter(
        (selected: BusinessTypes) => selected !== businessType
      )
    );
  };

  function handleError(message: string, error: ErrorResponse) {
    setErrorPopupData({
      boldMessage: message,
      message: error.result?.errorDesc || error.message,
    });
    setIsErrorPopupVisible(true);
  }

  function handleResponse(response: RegisterAppApiResponse) {
    setSuccessPopupData({
      boldMessage: "APP REGISTERED",
      message:
        "App has been successfully registered.\n Please navigate to My apps to view the details.",
    });
    setIsSuccessPopupVisible(true);
  }

  //TODO: Remove this hardcoded data and use the actual data
  const [appData, setAppData] = useState<RegisterAppData>(initialAppData);

  const fetchAppDetails = async () => {
    dispatch(getAppDetailsApiInitiated());
    try {
      const userInfo = await initiateApiCall(
        "get",
        `${process.env.REACT_APP_FEDERATION_USER_INFO_URI}`,
        null,
        {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        }
      );
      if (userInfo.data.fullname !== null) {
        setName(userInfo.data.fullname);
      }

      const userEmail = localStorage.getItem("user_email");

      const response: AxiosResponse<ApiResponse> = await initiateApiCall(
        "get",
        `${process.env.REACT_APP_BASE_URL}/app/find/app-details`,
        null,
        {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          apikey: `${process.env.REACT_APP_API_KEY}`,
          User: userEmail,
        },
        {
          "package-name": state.packageName,
          "platform-name": state.platformName,
        }
      );

      if (response.data.code === 200) {
        dispatch(getAppDetailsApiSucceed(response.data.result));
        handleSuccessResponse(response.data.result);
        if (response.data.result["app-details"]["business-types"]) {
          const businessTypesResponse =
            response.data.result["app-details"]["business-types"];

          setSelectedBusinessType(businessTypesResponse);

          setBusinessTypes(
            businessTypeArray.filter((type: any) => {
              return !businessTypesResponse.some(
                (selected: any) =>
                  selected["business-type-id"] === type["business-type-id"]
              );
            })
          );
        }
        if (response.data.result["app-details"]["business-lines"]) {
          const lbArray = response.data.result["app-details"]["business-lines"];
          setSelectedLB(lbArray);
          setAllLBs(
            businessLines.filter((line: any) => {
              return !lbArray.some((selected: any) => selected.id === line.id);
            })
          );
          //  setAllLBs(allLBs.filter((selected: LineOfBusiness) => selected !== lb));
        }
      } else if (response.data.code === 401) {
        clearCacheAndCookies();
        setAuthToken("");
        navigate("/");
      } else {
        dispatch(getAppDetailsApiFailed(response.data));
        setErrorPopupData({
          boldMessage: response.data.error,
          message: response.data.message,
        });
        setIsErrorPopupVisible(true);
      }
    } catch (error: any) {
      if (error instanceof AxiosError) {
        const axiosError = error as AxiosError;
        if (axiosError.response?.status === 401) {
          clearCacheAndCookies();
          setAuthToken("");
          navigate("/");
        } else if (axiosError.response) {
          handleErrorAppDetails(axiosError.response.data as ErrorResponse);
        } else {
          handleErrorAppDetails({
            code: 500,
            message: "Internal Server Error",
            result: null,
            error: "Internal Server Error",
          });
        }
        dispatch(getAppDetailsApiFailed(error));
      }
    }
  };

  useEffect(() => {
    fetchAppDetails();
    getLineOfBusiness();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    if (
      appDetails?.["app-details"]["store-app-name"] ||
      appDetails?.["app-details"]["app-name"]
    ) {
      setAppData((prevAppData) => ({
        ...prevAppData,
        ["app-name"]: {
          ...appData["app-name"],
          value: appDetails?.["app-details"]["store-app-name"]
            ? appDetails?.["app-details"]["store-app-name"]
            : appDetails?.["app-details"]["app-name"],
        },
      }));
    }
    if (
      appDetails?.["app-details"]["store-app-description"] ||
      appDetails?.["app-details"]["app-description"]
    ) {
      setAppData((prevAppData) => ({
        ...prevAppData,
        ["app-description"]: {
          ...appData["app-description"],
          value: appDetails?.["app-details"]["store-app-description"]
            ? appDetails?.["app-details"]["store-app-description"]
            : appDetails?.["app-details"]["app-description"],
        },
      }));
    }
    if (appDetails?.["app-details"]["package-name"]) {
      setAppData((prevAppData) => ({
        ...prevAppData,
        ["package-name"]: {
          ...appData["package-name"],
          value: appDetails?.["app-details"]["package-name"],
        },
      }));
    }
    if (appDetails?.["app-details"]["app-version"]) {
      setAppData((prevAppData) => ({
        ...prevAppData,
        ["app-version"]: {
          ...appData["app-version"],
          value: appDetails?.["app-details"]["app-version"],
        },
      }));
    }
    if (appDetails?.["app-details"]["published-app-name"]) {
      setAppData((prevAppData) => ({
        ...prevAppData,
        ["published-app-name"]: {
          ...appData["published-app-name"],
          value: appDetails?.["app-details"]["published-app-name"],
        },
      }));
    }
    if (
      appDetails?.["app-details"]["store-app-category"] ||
      appDetails?.["app-details"]["app-category"]
    ) {
      setAppData((prevAppData) => ({
        ...prevAppData,
        ["category"]: {
          ...appData["category"],
          value: appDetails?.["app-details"]["store-app-category"]
            ? appDetails?.["app-details"]["store-app-category"]
            : appDetails?.["app-details"]["app-category"],
        },
      }));
    }
    if (
      appDetails?.["app-details"]["store-app-published-url"] ||
      appDetails?.["app-details"]["app-published-url"]
    ) {
      setAppData((prevAppData) => ({
        ...prevAppData,
        ["publish-url"]: {
          ...appData["publish-url"],
          value: appDetails?.["app-details"]["store-app-published-url"]
            ? appDetails?.["app-details"]["store-app-published-url"]
            : appDetails?.["app-details"]["app-published-url"],
        },
      }));
    }
    if (
      appDetails?.["app-details"]["store-app-min-platform-version"] ||
      appDetails?.["app-details"]["app-min-platform-version"]
    ) {
      setAppData((prevAppData) => ({
        ...prevAppData,
        ["minimum-platform-version"]: {
          ...appData["minimum-platform-version"],
          value: appDetails?.["app-details"]["store-app-min-platform-version"]
            ? appDetails?.["app-details"]["store-app-min-platform-version"]
            : appDetails?.["app-details"]["app-min-platform-version"],
        },
      }));
    }
    if (
      appDetails?.["app-details"]["store-app-build-version"] ||
      appDetails?.["app-details"]["app-build-version"]
    ) {
      setAppData((prevAppData) => ({
        ...prevAppData,
        ["build-version"]: {
          ...appData["build-version"],
          value: appDetails?.["app-details"]["store-app-build-version"]
            ? appDetails?.["app-details"]["store-app-build-version"]
            : appDetails?.["app-details"]["app-build-version"],
        },
      }));
    }
    if (appDetails?.["app-details"]["privacy-policy-url"]) {
      setAppData((prevAppData) => ({
        ...prevAppData,
        ["privacy-url"]: {
          ...appData["privacy-url"],
          value: appDetails?.["app-details"]["privacy-policy-url"],
        },
      }));
    }
    if (
      appDetails?.["app-details"]["registrar-details"]["registered-by"] ||
      name
    ) {
      setAppData((prevAppData) => ({
        ...prevAppData,
        ["registered-by"]: {
          ...appData["registered-by"],
          value: appDetails?.["app-details"]["registrar-details"][
            "registered-by"
          ]
            ? appDetails?.["app-details"]["registrar-details"]["registered-by"]
            : name,
        },
      }));
    }
    if (
      appDetails?.["app-details"]["registrar-details"]["registrar-email"] ||
      localStorage.getItem("user_email")
    ) {
      setAppData((prevAppData) => ({
        ...prevAppData,
        ["registrar-email"]: {
          ...appData["registrar-email"],
          value: appDetails?.["app-details"]["registrar-details"][
            "registrar-email"
          ]
            ? appDetails?.["app-details"]["registrar-details"][
                "registrar-email"
              ]
            : localStorage.getItem("user_email"),
        },
      }));
    }
    if (appDetails?.["app-details"]["business-type"]) {
      setAppData((prevAppData) => ({
        ...prevAppData,
        ["business-type"]: {
          ...appData["business-type"],
          value: appDetails?.["app-details"]["business-type"],
        },
      }));
    }
    if (appDetails?.["app-details"]["contact-details"]["owner-email"]) {
      setAppData((prevAppData) => ({
        ...prevAppData,
        ["owner-email"]: {
          ...appData["owner-email"],
          value: appDetails?.["app-details"]["contact-details"]["owner-email"],
        },
      }));
    }
    if (appDetails?.["app-details"]["contact-details"]["support-email"]) {
      setAppData((prevAppData) => ({
        ...prevAppData,
        ["support-email"]: {
          ...appData["support-email"],
          value:
            appDetails?.["app-details"]["contact-details"]["support-email"],
        },
      }));
    }
    if (appDetails?.["app-details"]["platform-name"]) {
      setAppData((prevAppData) => ({
        ...prevAppData,
        ["platform-name"]: {
          ...appData["platform-name"],
          value: appDetails?.["app-details"]["platform-name"],
        },
      }));
    }
    if (appDetails?.["app-details"]["developer"]) {
      setAppData((prevAppData) => ({
        ...prevAppData,
        ["developer"]: {
          ...appData["developer"],
          value: appDetails?.["app-details"]["developer"],
        },
      }));
    }
    if (
      appDetails?.["app-details"]["store-app-logo"] ||
      appDetails?.["app-details"]["app-logo"]
    ) {
      setAppData((prevAppData) => ({
        ...prevAppData,
        ["app-icon"]: {
          ...appData["app-icon"],
          value: appDetails?.["app-details"]["store-app-logo"]
            ? appDetails?.["app-details"]["store-app-logo"]
            : appDetails?.["app-details"]["app-logo"],
          validationError: "",
        },
      }));
    }
  }, [
    appDetails?.["app-details"]["store-app-name"],
    appDetails?.["app-details"]["app-name"],
    appDetails?.["app-details"]["app-description"],
    appDetails?.["app-details"]["package-name"],
    appDetails?.["app-details"]["app-version"],
    appDetails?.["app-details"]["published-app-name"],
    appDetails?.["app-details"]["store-app-category"],
    appDetails?.["app-details"]["app-category"],
    appDetails?.["app-details"]["store-app-published-url"],
    appDetails?.["app-details"]["app-published-url"],
    appDetails?.["app-details"]["store-app-min-platform-version"],
    appDetails?.["app-details"]["app-min-platform-version"],
    appDetails?.["app-details"]["store-app-build-version"],
    appDetails?.["app-details"]["app-build-version"],
    appDetails?.["app-details"]["privacy-policy-url"],
    appDetails?.["app-details"]["registrar-details"]["registered-by"],
    appDetails?.["app-details"]["registrar-details"]["registrar-email"],
    appDetails?.["app-details"]["business-type"],
    appDetails?.["app-details"]["contact-details"]["owner-email"],
    appDetails?.["app-details"]["contact-details"]["support-email"],
    appDetails?.["app-details"]["platform-name"],
    appDetails?.["app-details"]["store-app-logo"],
    appDetails?.["app-details"]["app-logo"],
    appDetails?.["app-details"]["developer"],
  ]);

  const onFieldValueChange = (value: string, field: string) => {
    setAppData((prevAppData) => ({
      ...prevAppData,
      [field]: { ...appData[field], value: value, validationError: "" },
    }));
  };

  const onFieldBlur = (e: { target: { value: string } }, field: string) => {
    setAppData((prevAppData) => ({
      ...prevAppData,
      [field]: {
        ...appData[field],
        value: e.target.value.trim(),
        validationError: "",
      },
    }));
  };

  const validateData = async () => {
    const updatedAppData = { ...appData };
    let isInvalidData = false;

    for (const fieldKey in updatedAppData) {
      const field = updatedAppData[fieldKey];
      if (field.required) {
        const validationError = checkFieldEmpty(field.value);

        if (!isInvalidData && validationError !== "") isInvalidData = true;
        updatedAppData[fieldKey] = {
          ...updatedAppData[fieldKey],
          validationError,
        };
      }

      if (fieldKey.indexOf("email") > 0 || fieldKey.includes("developer")) {
        if (!checkFieldEmpty(field.value)) {
          const validationError = isValidEmail(field.value);
          if (!isInvalidData && validationError !== "") isInvalidData = true;
          updatedAppData[fieldKey] = {
            ...updatedAppData[fieldKey],
            validationError,
          };
        }
      }
      if (fieldKey.indexOf("url") > 0) {
        if (!checkFieldEmpty(field.value)) {
          const validationError = isValidURL(field.value);
          if (!isInvalidData && validationError !== "") isInvalidData = true;
          updatedAppData[fieldKey] = {
            ...updatedAppData[fieldKey],
            validationError,
          };
        }
      }
      if (fieldKey === "app-icon" && field.value) {
        const isBlobSizeOk = await checkIconSize(field.value);
        if (!isBlobSizeOk) {
          updatedAppData[fieldKey] = {
            ...updatedAppData[fieldKey],
            validationError: "Maximum file size exceeded!",
          };
          isInvalidData = true;
        }
      }
    }

    if (updatedAppData["app-version"]["validationError"] !== "") {
      appVersionRef.current?.scrollIntoView();
    } else if (updatedAppData["privacy-url"]["validationError"] !== "") {
      privacyPolicyUrlRef.current?.scrollIntoView();
    } else if (updatedAppData["developer"]["validationError"] !== "") {
      developerRef.current?.scrollIntoView();
    } else if (updatedAppData["registered-by"]["validationError"] !== "") {
      registrarByRef.current?.scrollIntoView();
    } else if (updatedAppData["registrar-email"]["validationError"] !== "") {
      registrarByRef.current?.scrollIntoView();
    } else if (updatedAppData["owner-email"]["validationError"] !== "") {
      ownerEmailRef.current?.scrollIntoView();
    } else if (updatedAppData["support-email"]["validationError"] !== "") {
      ownerEmailRef.current?.scrollIntoView();
    }
    setAppData(updatedAppData);
    return isInvalidData;
  };

  const handleSubmit = async () => {
    const isInvalidData = await validateData();

    if (isInvalidData) return;

    const appDataForRESTCall: { [key: string]: any } = {};
    for (const fieldKey in appData) {
      appDataForRESTCall[fieldKey] = appData[fieldKey].value;
    }
    appDataForRESTCall["business-lines"] = selectedLB.map((line) => line["id"]);
    appDataForRESTCall["business-types"] = selectedBusinessType.map(
      (businessType: { [x: string]: any }) => businessType["business-type-id"]
    );
    const formData = new FormData();

    formData.append(
      "app-info",
      new Blob([JSON.stringify(appDataForRESTCall)], {
        type: "application/json",
      })
    );

    formData.append("multipartFile", "");
    setIsProcessing(true);
    try {
      dispatch(postRegisterApiInitiated());
      const response = await initiateApiCall(
        "post",
        `${process.env.REACT_APP_BASE_URL}/app`,
        formData,
        {
          "App-Token": process.env.REACT_APP_REGISTRATION_TOKEN,
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          apikey: `${process.env.REACT_APP_API_KEY}`,
        }
      );

      if (response.status == 200) {
        const packageName = state.packageName;
        const platformName = state.platformName;
        updateAppCache(packageName, platformName, true);
        await uploadAppIcon(
          appData["app-icon"].value || dummyAppIcon,
          appData["package-name"].value,
          appData["platform-name"].value
        )
          .then((uploadResponse: any) => {
            if (uploadResponse.status == 200) {
              dispatch(postRegisterApiSucceed(response.data));
              const responseData = response.data as RegisterAppApiResponse;
              handleResponse(responseData);
            }
          })
          .catch((error) => {
            throw error;
          });
      }
    } catch (error: any) {
      if (error instanceof AxiosError) {
        const axiosError = error as AxiosError;
        if (axiosError.response?.status === 401) {
          clearCacheAndCookies();
          setAuthToken("");
          navigate("/");
        } else if (axiosError.response) {
          handleError(
            "APP NOT REGISTERED",
            axiosError.response.data as ErrorResponse
          );
        }
        dispatch(postRegisterApiFailed(error));
      }
    } finally {
      setIsProcessing(false);
    }
  };

  const handleUpdate = async () => {
    const isInvalidData = await validateData();

    if (isInvalidData) return;

    const appDataForRESTCall: { [key: string]: any } = {};
    for (const fieldKey in appData) {
      appDataForRESTCall[fieldKey] = appData[fieldKey].value;
    }
    appDataForRESTCall["business-lines"] = selectedLB.map((line) => line["id"]);
    appDataForRESTCall["business-types"] = selectedBusinessType.map(
      (businessType: { [x: string]: any }) => businessType["business-type-id"]
    );

    delete appDataForRESTCall["app-icon"];

    try {
      setIsProcessing(true);
      const response: any = await initiateApiCall(
        "patch",
        `${process.env.REACT_APP_BASE_URL}` + `/app`,
        appDataForRESTCall,
        {
          "App-Token": process.env.REACT_APP_REGISTRATION_TOKEN,
          User: localStorage.getItem("user_email"),
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          apikey: `${process.env.REACT_APP_API_KEY}`,
        }
      );
      if (response.data.code == 200) {
        setIsProcessing(false);
        setSuccessPopupData({
          boldMessage: "APP UPDATED",
          message: "App has been successfully updated.",
        });
        setIsSuccessPopupVisible(true);
      } else if (response.data.code === 401) {
        setIsProcessing(false);
        clearCacheAndCookies();
        setAuthToken("");
        navigate("/");
      } else {
        setIsProcessing(false);
        setErrorPopupData({
          boldMessage: response.data.error,
          message: response.data.message,
        });
        setIsErrorPopupVisible(true);
      }
    } catch (error: any) {
      setIsProcessing(false);
      if (error instanceof AxiosError) {
        const axiosError = error as AxiosError;
        if (axiosError.response?.status === 401) {
          clearCacheAndCookies();
          setAuthToken("");
          navigate("/");
        } else if (axiosError.response) {
          handleError(
            "APP NOT UPDATED",
            axiosError.response.data as ErrorResponse
          );
        }
        dispatch(postRegisterApiFailed(error));
      }
    }
  };

  const handleCloseError = () => {
    setErrorPopupData({ boldMessage: "", message: "" });
    setIsErrorPopupVisible(false);
  };
  const handleCloseSuccessPopup = () => {
    setIsSuccessPopupVisible(false);
    if (state.action === "update") {
      navigate(-1);
    }
  };

  const handleRegisterNewApp = () => {
    setIsSuccessPopupVisible(false);
  };

  const handleViewMyApps = () => {
    setIsSuccessPopupVisible(false);
    navigate("/myApps");
  };

  const getLineOfBusiness = async () => {
    dispatch(getLineOfBusinessApiInitiated());

    try {
      const response: AxiosResponse<LineOfBusinessApiResponse> =
        await initiateApiCall(
          "get",
          `${process.env.REACT_APP_BASE_URL}/lbs`,
          null,
          {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            apikey: `${process.env.REACT_APP_API_KEY}`,
          }
        );

      if (response.data.code === 200) {
        dispatch(getLineOfBusinessApiSucceed(response.data));
        setAllLBs(response.data.result);
      } else if (response.data.code === 401) {
        setIsProcessing(false);
        clearCacheAndCookies();
        setAuthToken("");
        navigate("/");
      } else {
        dispatch(getLineOfBusinessApiFailed(response.data));
        setIsErrorPopupVisible(true);
      }
    } catch (error: any) {
      if (error instanceof AxiosError) {
        const axiosError = error as AxiosError;
        if (axiosError.response?.status === 401) {
          clearCacheAndCookies();
          setAuthToken("");
          navigate("/");
        } else if (axiosError.response) {
          const errorResponse = axiosError.response.data as ErrorResponse;
          handleError(
            errorResponse.message,
            axiosError.response.data as ErrorResponse
          );
        } else {
          handleError("Internal Server Error", {
            code: 500,
            message: "Internal Server Error",
            result: null,
            error: "Internal Server Error",
          });
        }
        dispatch(getLineOfBusinessApiFailed(error));
      }
    }
  };

  const handleBackButton = () => {
    navigate(-1);
  };

  const inputRef: any = useRef(null);
  const overlayRef: any = useRef(null);
  const appVersionRef = useRef<HTMLDivElement>(null);
  const privacyPolicyUrlRef = useRef<HTMLDivElement>(null);
  const developerRef = useRef<HTMLDivElement>(null);
  const ownerEmailRef = useRef<HTMLDivElement>(null);
  const registrarByRef = useRef<HTMLDivElement>(null);

  const onChooseAppIconFile = () => {
    inputRef.current.click();
  };

  const deleteAppIcon = () => {
    setAppData({
      ...appData,
      ["app-icon"]: {
        ...appData["app-icon"],
        value: null,
        validationError: "",
      },
    });
  };

  const handleAppIconChange = async (event: any) => {
    if (event.target.files && event.target.files.length > 0) {
      setAppData({
        ...appData,
        ["app-icon"]: {
          ...appData["app-icon"],
          value: URL.createObjectURL(event.target.files[0]),
          validationError: "",
        },
      });
    }
    event.target.value = null;
  };

  const handleImageError = (e: any) => {
    e.target.src = uploadIcon;
    inputRef.current.disabled = false;
    setAppData((prevAppData) => ({
      ...prevAppData,
      ["app-icon"]: {
        ...appData["app-icon"],
        value: "",
        validationError: "",
      },
    }));
  };

  return (
    <div className="main-container">
      {apiCallInitiated && <ProcessingDialog message="Loading..." />}
      <img src={bannerImage} alt="Banner" className="banner" />
      <div className="heading heading-container bold flex-row">
        <img
          src={backIcon}
          alt="Back Icon"
          className="back-icon"
          onClick={handleBackButton}
        />
        {state.action === "register" ? "REGISTER NEW APP" : "UPDATE APP"}
      </div>
      <div className="form-container">
        <div className="form">
          <div className="form-padding">
            <div className="flex-row spacer">
              <div className="title-text bold">App Details</div>
              <div className="mandatory-fields red-text">
                Fields marked with * are mandatory
              </div>
            </div>
            <div className="fields-container">
              <div className="flex-row">
                <div className="lable">Select App Store:</div>
                {state ? (
                  <CustomCheckboxCard
                    icon={
                      state && appData["platform-name"]["value"] === "android"
                        ? playstoreIcon
                        : appstoreIcon
                    }
                    selectedIcon={
                      state && appData["platform-name"]["value"] === "android"
                        ? playstoreSelectedIcon
                        : appstoreSelectedIcon
                    }
                    altText={
                      state && appData["platform-name"]["value"] === "android"
                        ? "android"
                        : "ios"
                    }
                    checked={
                      state && appData["platform-name"]["value"] === "android"
                        ? appData["platform-name"].value === "android"
                        : appData["platform-name"].value === "ios"
                    }
                    setSelectedStore={(value: string) => {
                      onFieldValueChange(value, "platform-name");
                    }}
                  />
                ) : (
                  <>
                    <CustomCheckboxCard
                      icon={playstoreIcon}
                      selectedIcon={playstoreSelectedIcon}
                      altText="android"
                      checked={appData["platform-name"].value === "android"}
                      setSelectedStore={(value: string) => {
                        onFieldValueChange(value, "platform-name");
                      }}
                    />
                    <CustomCheckboxCard
                      icon={appstoreIcon}
                      selectedIcon={appstoreSelectedIcon}
                      altText="ios"
                      checked={appData["platform-name"].value === "ios"}
                      setSelectedStore={(value: string) => {
                        onFieldValueChange(value, "platform-name");
                      }}
                    />
                  </>
                )}
              </div>
              {/* 1st grid */}
              <div className="grid-container">
                {/* left side of grid */}
                <div>
                  <InputField
                    title="App Name"
                    placeholder="App Name"
                    datatTooltipContent="The name of your app"
                    dataTooltipId="app-name-id"
                    className={
                      appDetails?.["app-details"]["store-app-name"]
                        ? "fields-disabled"
                        : ""
                    }
                    styleType="input-field"
                    value={appData["app-name"]["value"] ?? ""}
                    onChange={(value: string) => {
                      onFieldValueChange(value, "app-name");
                    }}
                    onBlur={(e: { target: { value: string } }) =>
                      onFieldBlur(e, "app-name")
                    }
                    validationError={appData["app-name"].validationError}
                    disabled={
                      appDetails?.["app-details"]["store-app-name"]
                        ? true
                        : false
                    }
                    required
                  />
                </div>

                {/* right side of grid */}
                <div>
                  <InputField
                    title="App Icon"
                    type="noText"
                    styleType=""
                    disabled={
                      appData["app-icon"].value ===
                      appDetails?.["app-details"]["store-app-logo"]
                        ? true
                        : false
                    }
                    onChange={() => {
                      return;
                    }}
                  >
                    <div className="upload-container flex-row">
                      <input
                        type="file"
                        style={{ display: "none" }}
                        disabled={
                          appDetails?.["app-details"]["store-app-logo"]
                            ? true
                            : false
                        }
                        ref={inputRef}
                        onChange={handleAppIconChange}
                        accept="image/png, image/jpeg, image/jpg"
                      />
                      <div className="app-icon-wrapper">
                        <img
                          src={
                            appData["app-icon"].validationError
                              ? appIconUploadErrorImage
                              : appData["app-icon"].value || uploadIcon
                          }
                          alt="Upload Icon"
                          className={`upload-icon ${
                            appData["app-icon"].value &&
                            "app-icon-border-radius"
                          }`}
                          onClick={onChooseAppIconFile}
                          onError={handleImageError}
                        />
                        {!appData["app-icon"].value ? (
                          ""
                        ) : (
                          <div
                            className={`overlay ${
                              appData["app-icon"].value ===
                                appDetails?.["app-details"]["store-app-logo"] &&
                              "delete-icon-hide"
                            }`}
                            onClick={deleteAppIcon}
                            ref={overlayRef}
                          >
                            <img
                              className="remove-app-icon-margin"
                              src={removeAppIcon}
                              alt="Remove Icon"
                            />
                            &nbsp;
                            <span className="delete-app-icon-label">
                              Delete
                            </span>
                          </div>
                        )}
                      </div>

                      <p className="upload-text">
                        <span className="validation-error">
                          {appData["app-icon"].validationError}
                        </span>
                        <span>Upload app icon</span>
                        <span>
                          (App icon should be in .jpg or .png with less than
                          <b> 2MB </b> in size )
                        </span>
                      </p>
                    </div>
                  </InputField>
                </div>
              </div>

              <div>
                <InputField
                  title="App Description"
                  placeholder="App Description"
                  styleType=""
                  datatTooltipContent="The description of your app"
                  dataTooltipId="description-id"
                  className={
                    appDetails?.["app-details"]["store-app-description"]
                      ? "fields-disabled"
                      : ""
                  }
                  value={appData["app-description"]["value"] ?? ""}
                  type="textArea"
                  onChange={(value: string) => {
                    onFieldValueChange(value, "app-description");
                  }}
                  onBlur={(e: { target: { value: string } }) =>
                    onFieldBlur(e, "app-description")
                  }
                  validationError={appData["app-description"].validationError}
                  disabled={
                    appDetails?.["app-details"]["store-app-description"]
                      ? true
                      : false
                  }
                />
              </div>

              {/* 2nd grid */}
              <div className="grid-container">
                {/* left side of grid */}
                <div>
                  <InputField
                    title="Package Name"
                    placeholder="Package Name"
                    datatTooltipContent="The package name of your app. E.g: com.michelin.test"
                    dataTooltipId="package-name-id"
                    className="fields-disabled"
                    styleType="input-field"
                    value={appData["package-name"]["value"] ?? ""}
                    onChange={(value: string) => {
                      onFieldValueChange(value, "package-name");
                    }}
                    onBlur={(e: { target: { value: string } }) =>
                      onFieldBlur(e, "package-name")
                    }
                    disabled={true}
                    validationError={appData["package-name"].validationError}
                    required
                  />
                  <InputField
                    title="Published App Name"
                    placeholder="Published App Name"
                    datatTooltipContent="The published name of your app on store"
                    dataTooltipId="published-app-name-id"
                    styleType="input-field"
                    value={appData["published-app-name"]["value"] ?? ""}
                    onChange={(value: string) => {
                      onFieldValueChange(value, "published-app-name");
                    }}
                    onBlur={(e: { target: { value: string } }) =>
                      onFieldBlur(e, "published-app-name")
                    }
                    validationError={
                      appData["published-app-name"].validationError
                    }
                  />
                </div>

                {/* right side of grid */}
                <div ref={appVersionRef}>
                  <InputField
                    title="App Version"
                    placeholder="App Version"
                    datatTooltipContent="The version of your app e.g. 1.0.0.0"
                    dataTooltipId="app-version-id"
                    styleType={`input-field ${
                      appData["app-version"].validationError &&
                      "highlight-input-validation-error"
                    }`}
                    value={appData["app-version"]["value"] ?? ""}
                    onChange={(value: string) => {
                      onFieldValueChange(value, "app-version");
                    }}
                    onBlur={(e: { target: { value: string } }) =>
                      onFieldBlur(e, "app-version")
                    }
                    validationError={appData["app-version"].validationError}
                    required
                  />
                  <InputField
                    title="Category"
                    placeholder="Category"
                    datatTooltipContent="The category of an app on store"
                    dataTooltipId="category-id"
                    styleType="input-field"
                    className={
                      appDetails?.["app-details"]["store-app-category"]
                        ? "fields-disabled"
                        : ""
                    }
                    value={appData["category"]["value"] ?? ""}
                    onChange={(value: string) => {
                      onFieldValueChange(value, "category");
                    }}
                    onBlur={(e: { target: { value: string } }) =>
                      onFieldBlur(e, "category")
                    }
                    disabled={
                      appDetails?.["app-details"]["store-app-category"]
                        ? true
                        : false
                    }
                    validationError={appData["category"].validationError}
                  />
                </div>
              </div>
              <InputField
                title="Published URL"
                placeholder="Published URL"
                datatTooltipContent="The URL of published app on store"
                dataTooltipId="published-url-id"
                className={
                  appDetails?.["app-details"]["store-app-published-url"]
                    ? "fields-disabled"
                    : ""
                }
                value={appData["publish-url"]["value"] ?? ""}
                styleType="full-width"
                onChange={(value: string) => {
                  onFieldValueChange(value, "publish-url");
                }}
                onBlur={(e: { target: { value: string } }) =>
                  onFieldBlur(e, "publish-url")
                }
                validationError={appData["publish-url"].validationError}
                disabled={
                  appDetails?.["app-details"]["store-app-published-url"]
                    ? true
                    : false
                }
              />

              {/* 3rd grid */}
              <div className="grid-container">
                {/* left side of grid */}
                <div>
                  <InputField
                    title="Min. Platform Version"
                    placeholder="Min. Platform Version"
                    datatTooltipContent="The minimum platform version your app support"
                    dataTooltipId="min-platform-version-id"
                    styleType="input-field"
                    value={appData["minimum-platform-version"]["value"] ?? ""}
                    className={
                      appDetails?.["app-details"][
                        "store-app-min-platform-version"
                      ]
                        ? "fields-disabled"
                        : ""
                    }
                    onChange={(value: string) => {
                      onFieldValueChange(value, "minimum-platform-version");
                    }}
                    onBlur={(e: { target: { value: string } }) =>
                      onFieldBlur(e, "minimum-platform-version")
                    }
                    validationError={
                      appData["minimum-platform-version"].validationError
                    }
                    disabled={
                      appDetails?.["app-details"][
                        "store-app-min-platform-version"
                      ]
                        ? true
                        : false
                    }
                  />
                </div>

                {/* right side of grid */}
                <div>
                  <InputField
                    title="Build Version"
                    placeholder="Build Version"
                    datatTooltipContent="The build version of your app"
                    dataTooltipId="build-version-id"
                    styleType="input-field"
                    className={
                      appDetails?.["app-details"]["store-app-build-version"]
                        ? "fields-disabled"
                        : ""
                    }
                    value={appData["build-version"]["value"] ?? ""}
                    onChange={(value: string) => {
                      onFieldValueChange(value, "build-version");
                    }}
                    onBlur={(e: { target: { value: string } }) =>
                      onFieldBlur(e, "build-version")
                    }
                    disabled={
                      appDetails?.["app-details"]["store-app-build-version"]
                        ? true
                        : false
                    }
                    validationError={appData["build-version"].validationError}
                  />
                </div>
              </div>

              <div ref={privacyPolicyUrlRef}>
                <InputField
                  title="Privacy Policy URL"
                  placeholder="Privacy Policy URL"
                  datatTooltipContent="The privacy policy URL of your app used on store"
                  dataTooltipId="privacy-url-id"
                  value={appData["privacy-url"]["value"] ?? ""}
                  styleType={`full-width ${
                    appData["privacy-url"].validationError &&
                    "highlight-input-validation-error"
                  }`}
                  onChange={(value: string) => {
                    onFieldValueChange(value, "privacy-url");
                  }}
                  onBlur={(e: { target: { value: string } }) =>
                    onFieldBlur(e, "privacy-url")
                  }
                  validationError={appData["privacy-url"].validationError}
                  disabled={false}
                  required
                />
              </div>

              <div className="publisher-section">
                <div ref={developerRef} className="title-text bold">
                  Publisher Details
                </div>
                <InputField
                  title="Development Team Email"
                  placeholder="Developer / Development Team"
                  datatTooltipContent="The developer team email ID"
                  dataTooltipId="developer-team-id"
                  styleType={`full-width ${
                    appData["developer"].validationError &&
                    "highlight-input-validation-error"
                  }`}
                  value={appData["developer"]["value"] ?? ""}
                  onChange={(value: string) => {
                    onFieldValueChange(value, "developer");
                  }}
                  onBlur={(e: { target: { value: string } }) =>
                    onFieldBlur(e, "developer")
                  }
                  validationError={appData["developer"].validationError}
                />
              </div>

              {/* 4th grid */}
              <div className="grid-container">
                {/* left side of grid */}
                <div ref={registrarByRef}>
                  <InputField
                    title="Registrar By"
                    placeholder="Registrar By"
                    datatTooltipContent="The name of the person who is registering the app"
                    dataTooltipId="registrar-by-id"
                    styleType={`input-field ${
                      appData["registered-by"].validationError &&
                      "highlight-input-validation-error"
                    }`}
                    value={appData["registered-by"]["value"] ?? ""}
                    onChange={(value: string) => {
                      onFieldValueChange(value, "registered-by");
                    }}
                    onBlur={(e: { target: { value: string } }) =>
                      onFieldBlur(e, "registered-by")
                    }
                    validationError={appData["registered-by"].validationError}
                    required
                  />
                  <div className="lable">Business Type</div>
                  <div>
                    <BusinessTypeCapsuleComponent
                      businessTypes={businessTypes}
                      onSelect={handleBusinessTypes}
                      selectedBusinessType={selectedBusinessType}
                    />
                  </div>
                </div>

                {/* right side of grid */}
                <div>
                  <InputField
                    title="Registrar Email"
                    placeholder="Registrar Email"
                    datatTooltipContent="The email of the person who is registering the app"
                    dataTooltipId="registrar-email-id"
                    styleType={`input-field ${
                      appData["registrar-email"].validationError &&
                      "highlight-input-validation-error"
                    }`}
                    value={appData["registrar-email"]["value"] ?? ""}
                    onChange={(value: string) => {
                      onFieldValueChange(value, "registrar-email");
                    }}
                    onBlur={(e: { target: { value: string } }) =>
                      onFieldBlur(e, "registrar-email")
                    }
                    validationError={appData["registrar-email"].validationError}
                    required
                  />
                </div>
              </div>

              <div className="lable">
                Business Line / Corporate Direction / Operational Direction
              </div>
              <div>
                <CapsuleComponent
                  businessLines={allLBs}
                  onSelect={handleLbs}
                  selectedLBs={selectedLB}
                />
              </div>

              {/* 5th grid */}
              <div className="grid-container">
                {/* left side of grid */}
                <div ref={ownerEmailRef}>
                  <InputField
                    title="Owner Email"
                    placeholder="Owner Email"
                    datatTooltipContent="The email of app owner"
                    dataTooltipId="owner-email-id"
                    styleType={`input-field ${
                      appData["owner-email"].validationError &&
                      "highlight-input-validation-error"
                    }`}
                    value={appData["owner-email"]["value"] ?? ""}
                    onChange={(value: string) => {
                      onFieldValueChange(value, "owner-email");
                    }}
                    onBlur={(e: { target: { value: string } }) =>
                      onFieldBlur(e, "owner-email")
                    }
                    validationError={appData["owner-email"].validationError}
                    required
                  />
                </div>

                {/* right side of grid */}
                <div>
                  <InputField
                    title="Support Email"
                    placeholder="Support Email"
                    datatTooltipContent="The email of app support team"
                    dataTooltipId="support-email-id"
                    styleType={`input-field ${
                      appData["support-email"].validationError &&
                      "highlight-input-validation-error"
                    }`}
                    value={appData["support-email"]["value"] ?? ""}
                    onChange={(value: string) => {
                      onFieldValueChange(value, "support-email");
                    }}
                    onBlur={(e: { target: { value: string } }) =>
                      onFieldBlur(e, "support-email")
                    }
                    validationError={appData["support-email"].validationError}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {state.action === "register" ? (
          <div className="button-container">
            <button className="submit-button" onClick={handleSubmit}>
              Submit For Approval
            </button>
          </div>
        ) : (
          <div className="button-container">
            <button className="cancel-button" onClick={handleBackButton}>
              Cancel
            </button>
            <button className="submit-button" onClick={handleUpdate}>
              Update
            </button>
          </div>
        )}
      </div>
      {isProcessing && <ProcessingDialog message="Processing..." />}

      {isSuccessPopupVisible && (
        <SuccessPopup
          boldMessage={successPopupData.boldMessage}
          message={successPopupData.message}
          onClose={handleCloseSuccessPopup}
          onRegisterNewApp={handleRegisterNewApp}
          onViewMyApps={
            state.action === "register" ? handleViewMyApps : handleBackButton
          }
          stateAction={state.action}
        />
      )}

      {isErrorPopupVisible && (
        <ErrorPopup
          boldMessage={errorPopupData.boldMessage}
          message={errorPopupData.message}
          onClose={handleCloseError}
        />
      )}
    </div>
  );
};

export default RegisterAppScreen;
