import {
  POST_ANALYTICS_BY_STATUS_API_INITIATED,
  POST_ANALYTICS_BY_STATUS_API_SUCCEED,
  POST_ANALYTICS_BY_STATUS_API_FAILED,
} from "../constants/analyticsByStatusConstants";

const initialState = {
  apiCallInitiated: true,
  apiResponse: {},
  apiCallFailed: {},
};

const analyticsByStatusReducers = (state = initialState, action: any) => {
  switch (action.type) {
    case POST_ANALYTICS_BY_STATUS_API_INITIATED:
      return {
        apiCallInitiated: true,
        apiResponse: {},
        apiCallFailed: {},
      };
    case POST_ANALYTICS_BY_STATUS_API_SUCCEED:
      return {
        apiCallInitiated: false,
        apiResponse: action.payload,
        apiCallFailed: {},
      };
    case POST_ANALYTICS_BY_STATUS_API_FAILED:
      return {
        apiCallInitiated: false,
        apiResponse: {},
        apiCallFailed: action.payload,
      };
    default:
      return state;
  }
};

export default analyticsByStatusReducers;
