import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import AppReviewsComponent from "./appDetailsComponents/appReviewsComponent/AppReviewsComponent";
import "./AppDetailsScreen.scss";
import {
  backIcon,
  appDetailsPlayStore,
  appstoreIcon,
  editIcon,
} from "../../assets";
import AppImageWithDescription from "./appDetailsComponents/AppImageWithDescription";
import AppSecretMetaData from "./appDetailsComponents/AppSecretMetaData";
import AppMetaData from "./appDetailsComponents/AppMetaData";
import BusinessLines from "./appDetailsComponents/BusinessLines";
import ContactDetails from "./appDetailsComponents/ContactDetails";
import RegistrarDetails from "./appDetailsComponents/RegistrarDetails";
import { useDispatch, useSelector } from "react-redux";
import { ErrorResponse } from "../../interfaces/common/ErrorResponse";
import {
  getAppDetailsApiFailed,
  getAppDetailsApiInitiated,
  getAppDetailsApiSucceed,
} from "../../stateManagement/index";
import { initiateApiCall } from "../../api";
import { AxiosError, AxiosResponse } from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import Countries from "./appDetailsComponents/Countries";
import { clearCacheAndCookies } from "../../utils/Utils";
import { ProcessingDialog, ReviewPopup, ErrorPopup } from "../../components";
import EmptyMessageComponent from "../../components/emptyMessageComponent/EmptyMessageComponent";
import { useAuth } from "../../routes/useAuth";
interface ReviewsApiResponse {
  code: number;
  message: string;
  result: any;
  error: string;
}

export interface ApiResponse {
  code: number;
  message: string;
  result: appDetails;
  error: string;
}

export interface appDetails {
  "app-details": {
    "package-name": string;
    "platform-name": string;
    "app-name": string;
    "published-app-name": string;
    "store-app-name": string;
    "app-logo": string;
    "store-app-logo": string;
    "app-account-holder": string;
    "platform-registered": boolean;
    "app-last-published-date": Date;
    "avg-rating": number;
    "total-ratings": number;
    "no-of-reviews": number;
    "no-of-downloads": number;
    "app-description": string;
    "store-app-description": string;
    "app-category": string;
    "store-app-category": string;
    "business-type": string;
    "app-version": string;
    "build-version": string;
    "app-build-version": string;
    "privacy-policy-url": string;
    "store-app-build-version": string;
    "store-app-published-url": string;
    "store-app-min-platform-version": string;
    "app-min-platform-version": string;
    "app-published-url": string;
    "business-lines": [
      {
        id: number;
        "line-of-business": string;
        "business-direction": string;
      }
    ];
    "business-types": [
      {
        "business-type-id": number;
        "business-type-name": string;
        "business-type-desc": string;
      }
    ];
    territories: [string];
    "registrar-details": {
      "registered-by": string;
      "registrar-email": string;
    };
    "contact-details": {
      "owner-email": string;
      "support-email": string;
    };
    "app-secrets": {
      "APP-ID": string;
      "APP-SECRET": string;
    };
    "app-status": {
      "version-string": string;
      status: string;
      "app-version-state": string;
      reason: string;
    };
    "app-approval-status": string;
    developer: string;
  };
}

const AppDetailsScreen = () => {
  const { setAuthToken }: any = useAuth();
  const { apiCallInitiated, apiResponse } = useSelector(
    (state: any) => state.appDetailsReducers
  );

  const { state } = useLocation();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [reviewType, setReviewType] = useState("All");
  const [reviewTypeChangedFlag, setReviewTypeChangedFlag] = useState(false);
  const [reviewPageNumber, setReviewPageNumber] = useState(0);
  const [apiCallFlag, setApiCallFlag] = useState(true);
  const [enablePopup, setEnablePopup] = useState(false);
  const [showEmptyComponent, setShowEmptyComponent] = useState(false);
  const [reviewApiInitiated, setReviewApiInitiated] = useState(true);
  const [errorPopupData, setErrorPopupData] = useState({
    boldMessage: "",
    message: "",
  });
  const [isErrorPopupVisible, setIsErrorPopupVisible] = useState(false);
  const [appDetails, setAppDetails] = useState<appDetails>();

  const [reviewData, setReviewData] = useState<any>({});
  const [reviewList, setReviewList] = useState<any>([]);

  const ClampedDiv = ({ children }: any) => {
    const [open, setOpen] = useState(false);
    const ref = useRef<HTMLDivElement>(null);
    const [showLink, setShowLink] = useState(false);

    useLayoutEffect(() => {
      if (ref.current && ref.current.clientHeight < ref.current.scrollHeight) {
        setShowLink(true);
      }
    }, [ref]);

    let textClass = "app-description-text";
    if (open) {
      textClass += " read-more";
    }

    return (
      <>
        <div className={textClass} ref={ref}>
          {children}
        </div>
        {showLink && !open && (
          <span className="show-more-div" onClick={() => setOpen(true)}>
            more
          </span>
        )}
      </>
    );
  };

  const handleError = (error: ErrorResponse) => {
    setErrorPopupData({
      boldMessage: error.error,
      message: error.message,
    });
    setIsErrorPopupVisible(true);
  };

  const handleSuccessResponse = (response: ApiResponse) => {
    setAppDetails(response.result);
  };

  const handleCloseError = () => {
    setErrorPopupData({
      boldMessage: "",
      message: "",
    });
    setIsErrorPopupVisible(false);
    setShowEmptyComponent(true);
  };

  const fetchAppDetails = async () => {
    dispatch(getAppDetailsApiInitiated());
    try {
      const userEmail = localStorage.getItem("user_email");

      const response: AxiosResponse<ApiResponse> = await initiateApiCall(
        "get",
        `${process.env.REACT_APP_BASE_URL}/app/find/app-details`,
        null,
        {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          apikey: `${process.env.REACT_APP_API_KEY}`,
          User: userEmail,
          "Content-Type": "application/json",
        },
        {
          "package-name": state.packageName,
          "platform-name": state.platformName,
        }
      );

      if (response.data.code === 200) {
        dispatch(getAppDetailsApiSucceed(response.data.result));
        handleSuccessResponse(response.data);
      } else if (response.data.code === 401) {
        clearCacheAndCookies();
        setAuthToken("");
        navigate("/");
      } else {
        dispatch(getAppDetailsApiFailed(response.data));
        setErrorPopupData({
          boldMessage: response.data.error,
          message: response.data.message,
        });
        setIsErrorPopupVisible(true);
      }
    } catch (error: any) {
      if (error instanceof AxiosError) {
        const axiosError = error as AxiosError;
        if (axiosError.response?.status === 401) {
          clearCacheAndCookies();
          setAuthToken("");
          navigate("/");
        } else if (axiosError.response) {
          handleError(axiosError.response.data as ErrorResponse);
        } else {
          handleError({
            code: 500,
            message: "Internal Server Error",
            result: null,
            error: "Internal Server Error",
          });
        }
        dispatch(getAppDetailsApiFailed(error));
      }
    }
  };

  const fetchAppReviews = async () => {
    setReviewApiInitiated(true);
    try {
      const response: AxiosResponse<ReviewsApiResponse> = await initiateApiCall(
        "get",
        `${process.env.REACT_APP_BASE_URL}/app/reviews`,
        null,
        {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          apikey: `${process.env.REACT_APP_API_KEY}`,
        },
        {
          "package-name": state.packageName,
          "platform-name": state.platformName,
          feedback: reviewType,
          page: reviewPageNumber,
          limit: 10,
        }
      );

      if (response.data.code === 200) {
        if (response.data.result["app-review-list"].length === 0) {
          setApiCallFlag(false);
        }
        if (Object.keys(reviewData).length === 0 || reviewTypeChangedFlag) {
          setReviewData(response.data.result);

          setReviewList(response.data.result["app-review-list"]);
          setReviewTypeChangedFlag(false);
        } else {
          setReviewList((rd: any) => [
            ...rd,
            ...(response.data.result["app-review-list"] || []),
          ]);
        }
      } else if (response.status === 204) {
        console.log("No reviews found");
      }
      setReviewApiInitiated(false);
    } catch (error: any) {
      setReviewApiInitiated(false);
      if (error instanceof AxiosError) {
        const axiosError = error as AxiosError;
        if (axiosError.response?.status === 401) {
          clearCacheAndCookies();
          setAuthToken("");
          navigate("/");
        } else if (axiosError.response) {
          handleError(axiosError.response.data as ErrorResponse);
        } else {
          handleError({
            code: 500,
            message: "Internal Server Error",
            result: null,
            error: "Internal Server Error",
          });
        }
      }
    }
  };

  useEffect(() => {
    fetchAppDetails();
  }, []);

  useEffect(() => {
    fetchAppReviews();
  }, [reviewType, reviewPageNumber]);

  const handleBackButton = () => {
    navigate(-1);
  };

  const checkRatingPercentage = () => {
    if (
      reviewData["five-star-rating-percentage"] +
        reviewData["four-star-rating-percentage"] +
        reviewData["three-star-rating-percentage"] +
        reviewData["two-star-rating-percentage"] +
        reviewData["one-star-rating-percentage"] >
        0 &&
      reviewData["average-rating"] > 0
    ) {
      return true;
    } else {
      return false;
    }
  };

  const description: any = appDetails?.["app-details"]["store-app-description"]
    ? appDetails?.["app-details"]["store-app-description"]
    : appDetails?.["app-details"]["app-description"];

  return (
    <div className="main-container">
      {apiCallInitiated && <ProcessingDialog message="Loading..." />}
      {isErrorPopupVisible && (
        <ErrorPopup
          boldMessage={errorPopupData.boldMessage}
          message={errorPopupData.message}
          onClose={handleCloseError}
        />
      )}
      {enablePopup && (
        <ReviewPopup
          reviews={reviewList}
          setEnablePopup={setEnablePopup}
          appName={apiResponse["app-details"]["app-name"]}
          appIconUrl={apiResponse["app-details"]["store-app-logo"]}
          fallbackSrcUrl={apiResponse["app-details"]["app-logo"]}
          setReviewType={setReviewType}
          selectedTab={reviewType}
          pageNumber={reviewPageNumber}
          setReviewPageNumber={setReviewPageNumber}
          apiCallFlag={apiCallFlag}
          setReviewTypeChangedFlag={setReviewTypeChangedFlag}
          setApiCallFlag={setApiCallFlag}
          reviewApiInitiated={reviewApiInitiated}
        />
      )}
      {!apiCallInitiated && Object.keys(apiResponse).length > 0 && (
        <div className="app-details-sub-container">
          <div className="app-details-heading">
            <div className="app-name-container">
              <img
                src={backIcon}
                alt="Back Icon"
                className="back-icon"
                onClick={handleBackButton}
              />
              <div>APP DETAILS</div>
            </div>
            <div>
              <img
                src={
                  apiResponse["app-details"]["platform-name"] === "ios"
                    ? appstoreIcon
                    : appDetailsPlayStore
                }
                alt="App Details Play Store"
                width="108px"
                height="24px"
              />
              {(apiResponse["app-details"]["registrar-details"][
                "registrar-email"
              ] === localStorage.getItem("user_email") ||
                apiResponse["app-details"]["contact-details"]["owner-email"] ===
                  localStorage.getItem("user_email")) &&
                apiResponse["app-details"]["app-approval-status"] !==
                  "soft_registered" && (
                  <img
                    src={editIcon}
                    alt="App Details Play Store"
                    className="app-details-icon"
                    onClick={() => {
                      navigate("/registerApp", {
                        state: {
                          packageName:
                            apiResponse["app-details"]["package-name"],
                          platformName:
                            apiResponse["app-details"]["platform-name"],
                          from: "appDetails",
                          action: "update",
                        },
                      });
                    }}
                  />
                )}
            </div>
          </div>
          <div className="app-image-with-description-wrapper">
            <div className="app-image-with-description-subcontainer">
              <AppImageWithDescription appDetails={apiResponse} />
              {description && (
                <div className="about-app-label">About this app</div>
              )}
              <div className="app-description">
                {description && (
                  <>
                    <ClampedDiv>{description}</ClampedDiv>
                  </>
                )}
              </div>
              <div className="app-meta-data-container">
                <div className="app-secrets-reviews-wrapper">
                  <AppSecretMetaData appSecretMetaData={apiResponse} />
                  {reviewData["total-reviews"] > 0 ||
                  checkRatingPercentage() ? (
                    <>
                      <AppReviewsComponent
                        reviewsData={reviewData}
                        setEnablePopup={setEnablePopup}
                        setReviewType={setReviewType}
                        selectedTab={reviewType}
                        setReviewTypeChangedFlag={setReviewTypeChangedFlag}
                        setReviewPageNumber={setReviewPageNumber}
                        setApiCallFlag={setApiCallFlag}
                        checkRatingPercentage={checkRatingPercentage}
                      />
                    </>
                  ) : (
                    <div className="no-app-reviews">
                      <div className="app-reviews-heading-styling">
                        App Reviews
                      </div>
                      <div className="no-reviews-label">
                        No customer reviews!
                      </div>
                    </div>
                  )}
                </div>
                <div className="app-meta-data-subcontainer">
                  <AppMetaData appMetaData={apiResponse} />
                  <BusinessLines businessLines={apiResponse} />
                  <Countries countriesData={apiResponse} />
                  <RegistrarDetails registrarDetails={apiResponse} />
                  <ContactDetails contactDetails={apiResponse} />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {showEmptyComponent && (
        <EmptyMessageComponent message="No details found!" />
      )}
    </div>
  );
};

export default AppDetailsScreen;
