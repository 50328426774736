import React from "react";
import "./ProfilePopup.scss";
import { PopupProfileImage } from "../../assets";
import { clearCacheAndCookies } from "../../utils/Utils";
import { useNavigate } from "react-router-dom";
import packageJson from "../../../package.json";
import { useAuth } from "../../routes/useAuth";

const ProfilePopup = () => {
  const { setAuthToken } = useAuth();
  const versionNumber = packageJson.version;
  const releaseDate = packageJson.releaseDate;
  const releaseMessage = packageJson.releaseMessage;
  const appName = packageJson.appName;
  const userEmail = localStorage.getItem("user_email") || "userEmail@email.com";
  const navigate = useNavigate();
  const handleLogout = () => {
    clearCacheAndCookies();
    setAuthToken("");
    navigate("/");
  };
  return (
    <div className="popup-wrapper">
      <div className="profile-popup profile-popup-background">
        <div className="profile-popup-upper">
          <img
            src={PopupProfileImage}
            alt="Profile"
            className="profile-picture"
          />
          <div className="user-info">
            <div className="user-email">{userEmail}</div>
          </div>
        </div>
        <div className="profile-popup-lower">
          <button className="logout-button" onClick={handleLogout}>
            Logout
          </button>
          <div className="mobile-platform">{appName}</div>
          <div className="version-name">{`v${versionNumber} ${releaseMessage} ${releaseDate}`}</div>
        </div>
      </div>
    </div>
  );
};

export default ProfilePopup;
