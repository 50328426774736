import React from "react";
import "./ReviewCardComponent.scss";
import { profileIcon } from "../../assets";
import StarRatingsComponent from "../starRatingsComponent/StarRatingsComponent";
import { formatReviewDate } from "../../utils/Utils";

type Props = {
  reviews: Array<{ name: string; review: string; rating: number }>;
  reviewCardBackground?: string;
};
const reviewCardBackgroundColors: any = {
  default: "review-card-background-white",
  primary: "review-card-background-grey",
};

const ReviewCardComponent = ({
  reviews,
  reviewCardBackground = "default",
}: Props) => {
  return (
    <>
      {reviews.map((data: any, index) => (
        <div
          key={index}
          className={`card-container ${reviewCardBackgroundColors[reviewCardBackground]}`}
        >
          <img src={profileIcon} alt="profile icon" className="profile-icon" />
          <div className="content-container">
            <div className="user-name">{data["reviewer-nickname"]}</div>
            <div className="ratings-container">
              <div className="ratings">
                <StarRatingsComponent rating={data["app-rating"]} />
              </div>
              <div className="date">
                {formatReviewDate(data["review-created-date"], 'en-US')}
              </div>
            </div>
            <div className="card-description">{data["app-review-body"]}</div>
          </div>
        </div>
      ))}
    </>
  );
};

export default ReviewCardComponent;
