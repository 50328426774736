import {
  POST_ANALYTICS_BY_STATUS_API_INITIATED,
  POST_ANALYTICS_BY_STATUS_API_SUCCEED,
  POST_ANALYTICS_BY_STATUS_API_FAILED,
} from "../constants/analyticsByStatusConstants";

export const postAnalyticsByStatusApiInitiated = () => ({
  type: POST_ANALYTICS_BY_STATUS_API_INITIATED,
});

export const postAnalyticsByStatusApiSucceed = (payload: any) => ({
  type: POST_ANALYTICS_BY_STATUS_API_SUCCEED,
  payload: payload,
});

export const postAnalyticsByStatusApiFailed = (payload: any) => ({
  type: POST_ANALYTICS_BY_STATUS_API_FAILED,
  payload: payload,
});
