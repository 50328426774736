import {
    GET_MY_APPS_API_INITIATED,
    GET_MY_APPS_API_SUCCEED,
    GET_MY_APPS_API_FAILED,
    DELETE_MY_APPS_API_INITIATED,
    DELETE_MY_APPS_API_SUCCEED,
    DELETE_MY_APPS_API_FAILED,
} from "../constants/myAppsConstants";

export const getMyAppsApiInitiated = () => ({
    type: GET_MY_APPS_API_INITIATED,
});

export const getMyAppsApiSucceed = (payload: any) => ({
    type: GET_MY_APPS_API_SUCCEED,
    payload: payload,
});

export const getMyAppsApiFailed = (payload: any) => ({
    type: GET_MY_APPS_API_FAILED,
    payload: payload,
});

export const deleteMyAppsApiInitiated = () => ({
    type: DELETE_MY_APPS_API_INITIATED,
});

export const deleteMyAppsApiSucceed = (payload: any) => ({
    type: DELETE_MY_APPS_API_SUCCEED,
    payload: payload,
});

export const deleteMyAppsApiFailed = (payload: any) => ({
    type: DELETE_MY_APPS_API_FAILED,
    payload: payload,
});
