import {
  GET_ALL_APPS_API_INITIATED,
  GET_ALL_APPS_API_SUCCEED,
  GET_ALL_APPS_API_FAILED,
  GET_APP_STATISTICS_INITIATED,
  GET_APP_STATISTICS_SUCCEED,
  GET_APP_STATISTICS_FAILED,
} from "../constants/dashboardConstants";

const initialState = {
  allAppsApiCallInitiated: false,
  allAppsApiResponse: {},
  allAppsApiCallFailed: {},
  appStatisticsApiCallInitiated: false,
  appStatisticsApiResponse: {},
  appStatisticsApiCallFailed: {},
};

const dahsboardReducers = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_ALL_APPS_API_INITIATED:
      return {
        ...state,
        allAppsApiCallInitiated: true,
        allAppsApiResponse: {},
        allAppsApiCallFailed: {},
      };
    case GET_ALL_APPS_API_SUCCEED:
      return {
        ...state,
        allAppsApiCallInitiated: false,
        allAppsApiResponse: action.payload,
        allAppsApiCallFailed: {},
      };
    case GET_ALL_APPS_API_FAILED:
      return {
        ...state,
        allAppsApiCallInitiated: false,
        allAppsApiResponse: {},
        allAppsApiCallFailed: action.payload,
      };
    case GET_APP_STATISTICS_INITIATED:
      return {
        ...state,
        appStatisticsApiCallInitiated: true,
        appStatisticsApiResponse: {},
        appStatisticsApiCallFailed: {},
      };
    case GET_APP_STATISTICS_SUCCEED:
      return {
        ...state,
        appStatisticsApiCallInitiated: false,
        appStatisticsApiResponse: action.payload,
        appStatisticsApiCallFailed: {},
      };
    case GET_APP_STATISTICS_FAILED:
      return {
        ...state,
        appStatisticsApiCallInitiated: false,
        appStatisticsApiResponse: {},
        appStatisticsApiCallFailed: action.payload,
      };
    default:
      return state;
  }
};

export default dahsboardReducers;
