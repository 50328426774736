import React from "react";
import "./AppAnalyticsHomeScreen.scss";
import {
  analyticsByBusinessLinesXLarge,
  analyticsByStatusXLarge,
  analyticsByRegionXLarge,
  analyticsByPersonaXLarge,
} from "../../assets/index";
import { useNavigate } from "react-router-dom";

const AppAnalyticsHomeScreen = () => {
  const navigate = useNavigate();

  return (
    <div className="main-container">
      <div className="app-analytics-heading-home-screen">APP ANALYTICS</div>
      <div className="analytics-home-screen-container">
        <div className="analytics-categories">
          <img
            className="analytics-images"
            src={analyticsByBusinessLinesXLarge}
            alt="Analytics by Business Lines"
          />
          <div className="analytics-categories-label">
            Analytics By Business Line
          </div>
          <div className="analytics-categories-description">
            Categorization by Business Lines
          </div>
          <button
            className="start-analytics-button"
            onClick={() => navigate("/appAnalyticsByLb")}
          >
            Start
          </button>
        </div>
        <div className="analytics-categories">
          <img
            className="analytics-images"
            src={analyticsByStatusXLarge}
            alt="Analytics by Status"
          />
          <div className="analytics-categories-label">Analytics By Status</div>
          <div className="analytics-categories-description">
            Categorization by App Status
          </div>
          <button
            className="start-analytics-button"
            onClick={() => navigate("/appAnalyticsByStatus")}
          >
            Start
          </button>
        </div>
        <div className="analytics-categories">
          <img
            className="analytics-images"
            src={analyticsByRegionXLarge}
            alt="Analytics by Region"
          />
          <div className="analytics-categories-label">Analytics By Region</div>
          <div className="analytics-categories-description">
            Categorization by Region
          </div>
          <button className="start-analytics-button">Coming soon..</button>
        </div>
        <div className="analytics-categories">
          <img
            className="analytics-images"
            src={analyticsByPersonaXLarge}
            alt="Analytics by Persona"
          />
          <div className="analytics-categories-label">Analytics By Persona</div>
          <div className="analytics-categories-description">
            Categorization by Persona
          </div>
          <button className="start-analytics-button">Coming soon..</button>
        </div>
      </div>
    </div>
  );
};

export default AppAnalyticsHomeScreen;
