import React, { useState } from "react";
import MyAppsView from "./MyAppsView";
import "./MyAppScreen.scss";

const MyAppsScreen = () => {
  const tabs: string[] = ["My Apps", "Team", "Persona"];
  const [tabSelected, setTabSelected] = useState<string>("My Apps");

  const handleTabClick = (name: string) => {
    setTabSelected(name);
  };

  const renderTab = (tabSelected: string) => {
    switch (tabSelected) {
      case "My Apps":
        return <MyAppsView />;
      case "Team":
        return <></>;
      case "Persona":
        return <></>;
    }
  };

  return (
    <div className="main-container">
      <div className="my-apps-heading bold">MY APPS</div>
      <div className="my-apps-container">
        <div className="my-apps-tabs-options-wrapper">
          {tabs.map((item: string, index: number) => (
            <div
              key={index}
              className={`my-apps-tabs-label ${
                tabSelected === item && "active-button"
              }`}
              onClick={() => handleTabClick(item)}
            >
              {item}
            </div>
          ))}
        </div>
        <div className="line-bottom"></div>
        {renderTab(tabSelected)}
      </div>
    </div>
  );
};

export default MyAppsScreen;
