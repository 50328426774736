import { initiateApiCall } from "../api";
import { AxiosError, AxiosResponse } from "axios";

const formatNumberToK = (noOfDownloads: number) => {
  if (noOfDownloads < 1000) {
    return noOfDownloads;
  }
  //const scaled = Math.round((noOfDownloads / 1000) * 10) / 10;
  const scaled = noOfDownloads / 1000;

  return `${scaled}K`;
};

const formatNumberToSign = (noOfDownloads: number) => {
  if (noOfDownloads < 1000) {
    return noOfDownloads;
  }
  if (noOfDownloads >= 10000000)
    return `${Math.round(noOfDownloads / 10000000)}M`;
  if (noOfDownloads >= 100000) return `${Math.round(noOfDownloads / 100000)}L`;
  if (noOfDownloads >= 1000) return `${Math.round(noOfDownloads / 1000)}K`;
};

const formatDate = (date: any) => {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const day = date.getDate();
  const month = monthNames[date.getMonth()]; // Get month name from array
  const year = date.getFullYear();

  return `${day} ${month} ${year}`;
};

const checkFieldEmpty = (value: string) =>
  value && value.trim().length ? "" : "Field value is empty";

const isValidEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
    ? ""
    : "Email is not valid";
};

const isValidURL = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[\w]*))?)/
    )
    ? ""
    : "URL is not valid";
};

const clearCacheAndCookies = () => {
  localStorage.removeItem("allApps");
  localStorage.removeItem("appStatistics");
  localStorage.clear();
  sessionStorage.clear();

  document.cookie.split(";").forEach((cookie) => {
    document.cookie = cookie
      .replace(/^ +/, "")
      .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
  });
};

const getSliceValueBasedOnScreenSize = () => {
  const screenWidth = window.innerWidth;
  if (screenWidth >= 1920) {
    return 7;
  } else if (screenWidth >= 1280) {
    return 5;
  } else if (screenWidth < 1280) {
    return 3;
  }
};

const uploadAppIcon = async (
  appIconUrl: any,
  package_name: string,
  platform_name: string
) =>
  // eslint-disable-next-line no-async-promise-executor
  new Promise(async (resolve, reject) => {
    try {
      const iconName = package_name.split(".").pop() + "-" + platform_name;
      const file: any = await imageURLToFile(appIconUrl, iconName).catch(
        (error) => {
          throw error;
        }
      );
      const formData = new FormData();
      formData.append("multipartFile", file);
      const response = await initiateApiCall(
        "post",
        `${process.env.REACT_APP_BASE_URL}/app/upload-icon`,
        formData,
        {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          apikey: `${process.env.REACT_APP_API_KEY}`,
        },
        { "package-name": package_name, "platform-name": platform_name }
      );

      if (response) {
        resolve(response);
      }
    } catch (error: any) {
      if (error.status === 401) {
        reject("Error: Request failed with status code 401");
      } else if (error.status >= 400 && error.status <= 599) {
        reject(error);
      } else {
        reject(error);
      }
    }
  });

const checkIconSize = async (imgUrl: any) => {
  const response = await fetch(imgUrl);
  const blob = await response.blob();
  if (blob.size > 2000000) {
    return false;
  }
  return true;
};

const imageURLToFile = async (imgUrl: any, imgName: string) => {
  const response = await fetch(imgUrl);
  const blob = await response.blob();
  const mimeType = blob.type.split("/")[1];
  const file = new File([blob], `${imgName}.${mimeType}`, {
    type: blob.type,
  });

  return file;
};

const convertToBase64 = (clientId: string, clientSecret: string) => {
  return btoa(`${clientId}:${clientSecret}`);
};

const formatReviewDate = (dateString: string | null, locale: string) => {
  if (!dateString || dateString.trim() === "") return "";

  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  const date = new Date(dateString);
  return date.toLocaleDateString(locale, options);
};

const validateTokenExpiry = async () => {
  try {
    const response: any = await initiateApiCall(
      "post",
      `${process.env.REACT_APP_BASE_URL}/platform/authorize`,
      null,
      {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        apikey: `${process.env.REACT_APP_API_KEY}`,
      }
    );

    if (response.data.code === 200) {
      return true;
    } else if (response.data.code === 401) {
      return false;
    } else {
      return false;
    }
  } catch (error: any) {
    if (error instanceof AxiosError) {
      const axiosError = error as AxiosError;
      if (axiosError.response?.status === 401) {
        return false;
      } else {
        return false;
      }
    }
  }
};

const updateAppCache = async (
  packageName: string,
  platformName: string,
  isRegistered: boolean
) => {
  let appList: any = localStorage.getItem("allApps");
  appList = JSON.parse(appList);
  appList.forEach((app: any) => {
    if (
      app["package-name"] === packageName &&
      app["platform-name"] === platformName
    ) {
      app["platform-registered"] = isRegistered;
    }
  });
  localStorage.setItem("allApps", JSON.stringify(appList));
};

export {
  formatDate,
  formatNumberToK,
  formatNumberToSign,
  checkFieldEmpty,
  isValidEmail,
  isValidURL,
  clearCacheAndCookies,
  getSliceValueBasedOnScreenSize,
  uploadAppIcon,
  checkIconSize,
  convertToBase64,
  formatReviewDate,
  validateTokenExpiry,
  updateAppCache,
};
