import {
  GET_MY_APPS_API_INITIATED,
  GET_MY_APPS_API_SUCCEED,
  GET_MY_APPS_API_FAILED,
  DELETE_MY_APPS_API_INITIATED,
  DELETE_MY_APPS_API_SUCCEED,
  DELETE_MY_APPS_API_FAILED,
} from "../constants/myAppsConstants";

const initialState = {
  myAppsApiCallInitiated: true,
  myAppsApiResponse: [],
  myAppsApiCallFailed: {},
  deleteMyAppsApiCallInitiated: true,
  deleteMyAppsApiCallResponse: {},
  deleteMyAppsApiCallFailed: {},
  deleteMyAppsSuccessMessage: "",
};

const myAppsReducers = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_MY_APPS_API_INITIATED:
      return {
        myAppsApiCallInitiated: true,
        myAppsApiResponse: [],
        myAppsApiCallFailed: {},
      };
    case GET_MY_APPS_API_SUCCEED:
      return {
        myAppsApiCallInitiated: false,
        myAppsApiResponse: action.payload.result["my-apps"],
        myAppsApiCallFailed: {},
      };
    case GET_MY_APPS_API_FAILED:
      return {
        myAppsApiCallInitiated: false,
        myAppsApiResponse: [],
        myAppsApiCallFailed: action.payload,
      };
    case DELETE_MY_APPS_API_INITIATED:
      return {
        deleteMyAppsApiCallInitiated: true,
        deleteMyAppsApiCallResponse: {},
        deleteMyAppsApiCallFailed: {},
      };
    case DELETE_MY_APPS_API_SUCCEED:
      return {
        ...state,
        myAppsApiCallInitiated: false,
        deleteMyAppsApiCallInitiated: false,
        myAppsApiResponse: action.payload[0].filter(
          (item: any, index: any) =>
            item["APP-ID"] !== action.payload[1].result["APP-ID"] &&
            item["APP-SECRET"] !== action.payload[1].result["APP-SECRET"]
        ),
        deleteMyAppsSuccessMessage: action.payload[1].message,
      };
    case DELETE_MY_APPS_API_FAILED:
      return {
        deleteMyAppsApiCallInitiated: false,
        deleteMyAppsApiCallResponse: {},
        deleteMyAppsApiCallFailed: action.payload,
      };
    default:
      return state;
  }
};
export default myAppsReducers;
