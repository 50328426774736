import {
  POST_CONTACT_US_API_INITIATED,
  POST_CONTACT_US_API_SUCCEED,
  POST_CONTACT_US_API_FAILED,
} from "../constants/contactUsConstants";

const initialState = {
  apiCallInitiated: false,
  apiResponse: {},
  apiCallFailed: {},
};

const contactUsReducers = (state = initialState, action: any) => {
  switch (action.type) {
    case POST_CONTACT_US_API_INITIATED:
      return {
        apiCallInitiated: true,
        apiResponse: {},
        apiCallFailed: {},
      };
    case POST_CONTACT_US_API_SUCCEED:
      return {
        apiCallInitiated: false,
        apiResponse: action.payload,
        apiCallFailed: {},
      };
    case POST_CONTACT_US_API_FAILED:
      return {
        apiCallInitiated: false,
        apiResponse: {},
        apiCallFailed: action.payload,
      };
    default:
      return state;
  }
};

export default contactUsReducers;
