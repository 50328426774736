import { combineReducers } from "redux";
import registerAppReducers from "./registerAppReducers";
import myAppsReducers from "./myAppsReducers";
import dashboardReducers from "./dashboardReducers";
import appDetailsReducers from "./appDetailsReducers";
import contactUsReducers from "./contactUsReducers";
import analyticsByStatusReducers from "./analyticsByStatusReducers";

export default combineReducers({
  registerAppReducers,
  myAppsReducers,
  dashboardReducers,
  appDetailsReducers,
  contactUsReducers,
  analyticsByStatusReducers,
});
