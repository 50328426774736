import React, { useEffect } from "react";
import { Routes, Route, Outlet, Navigate } from "react-router-dom";
import DashboardScreen from "../screens/dashboardScreen/DashboardScreen";
import MyAppsScreen from "../screens/myAppsScreen/MyAppsScreen";
import RegisterAppScreen from "../screens/registerAppScreen/RegisterAppScreen";
import AppDetailsScreen from "../screens/appDetailsScreen/AppDetailsScreen";
import AppAnalyticsByLbScreen from "../screens/appAnalyticsScreen/appAnalyticsByLb/AppAnalyticsByLbScreen";
import AppAnalyticsByStatusScreen from "../screens/appAnalyticsScreen/appAnalyticsByStatus/AppAnalyticsByStatusScreen";
import AppAnalyticsHomeScreen from "../screens/appAnalyticsScreen/AppAnalyticsHomeScreen";
import LoginScreen from "../screens/loginScreen/LoginScreen";
import ContactUsScreen from "../screens/contactUsScreen/ContactUsScreen";
import RequestStatusPage from "../screens/requestStatusPage/RequestStatusPage";
import { Header, Footer } from "../components";
import { useAuth, AuthContext } from "./useAuth";
import { useNavigate } from "react-router-dom";
import DeveloperPortal from "../screens/developerPortalScreen/DeveloperPortal";

const PrivateRoutes = () => {
  const { authToken }: any = useAuth();
  return authToken ? (
    <>
      <Header />
      <Outlet />
      <Footer />
    </>
  ) : (
    <LoginScreen />
  );
};

const RouteConfig = () => {
  const [authToken, setAuthToken] = React.useState<string>("");
  const navigate = useNavigate();

  useEffect(() => {
    const currentUrl = window?.location.href;
    const searchParams = new URL(currentUrl).searchParams;
    const pathName = window.location.pathname;
    console.log("Pathname", pathName.split("/")[1], pathName.split("/")[2]);

    if (pathName.split("/")[1] === "reg") {
      navigate("/requestStatusPage", {
        state: {
          requestStatus: pathName.split("/")[2],
          token: searchParams.get("t"),
          approvalFor: pathName.split("/")[1],
        },
      });
    }

    const token = localStorage.getItem("token") ?? "";
    setAuthToken(token);
  }, []);

  return (
    <AuthContext.Provider value={{ authToken, setAuthToken }}>
      <Routes>
        <Route path="/" element={<LoginScreen />} />
        <Route path="/requestStatusPage" element={<RequestStatusPage />} />
        <Route element={<PrivateRoutes />}>
          <Route path="/dashboard" element={<DashboardScreen />} />
          <Route path="/myApps" element={<MyAppsScreen />} />
          <Route path="/registerApp" element={<RegisterAppScreen />} />
          <Route path="/appDetails" element={<AppDetailsScreen />} />
          <Route path="/home-analytics" element={<AppAnalyticsHomeScreen />} />
          <Route
            path="/appAnalyticsByLb"
            element={<AppAnalyticsByLbScreen />}
          />
          <Route
            path="/appAnalyticsByStatus"
            element={<AppAnalyticsByStatusScreen />}
          />
          <Route path="/contactUs" element={<ContactUsScreen />} />
          <Route path="/developerPortal" element={<DeveloperPortal />} />
          <Route path="*" element={<Navigate to="/dashboard" />} />
        </Route>
      </Routes>
    </AuthContext.Provider>
  );
};

export default RouteConfig;
