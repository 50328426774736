import React from "react";
import "../../components/capsuleComponent/CapsuleComponent.scss";
import { crossIcon } from "../../assets";
import { BusinessTypes } from "./RegisterAppScreen";

type Props = {
  businessTypes: BusinessTypes[];
  onSelect: (line: BusinessTypes, operation?: string) => void;
  selectedBusinessType: BusinessTypes[];
};

const BusinessTypeCapsuleComponent = ({
  businessTypes,
  onSelect,
  selectedBusinessType,
}: Props) => {
  return (
    <div
      className={
        "main-capsule-container" +
        (selectedBusinessType.length > 0 ? " chosen" : "")
      }
    >
      <div className="text-style">Select Business Type</div>
      <div className={"capsule-container"}>
        {selectedBusinessType.length > 0 && (
          <div className="seleted-container">
            {selectedBusinessType.map((businessType: any, index: any) => (
              <button key={index} className="capsule-button active">
                {businessType["business-type-name"]}
                <img
                  src={crossIcon}
                  alt="close"
                  className="close-icon"
                  onClick={() => onSelect(businessType, "remove")}
                />
              </button>
            ))}
            <div
              className="clear-button"
              onClick={() =>
                onSelect(
                  {
                    "business-type-id": 0,
                    "business-type-name": "",
                    "business-type-desc": "",
                  },
                  "clearAll"
                )
              }
            >
              Clear
            </div>
          </div>
        )}
        {businessTypes.map((businessType: any, index: any) => (
          <button
            key={index}
            className="capsule-button"
            onClick={() => onSelect(businessType)}
          >
            {businessType["business-type-name"]}
          </button>
        ))}
      </div>
    </div>
  );
};

export default BusinessTypeCapsuleComponent;
