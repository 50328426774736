import React from "react";
import "./AppCountComponent.scss";
import { appleLogo, playstoreLogo } from "../../assets";
import { AppStatisticsData } from "../../screens/dashboardScreen/DashboardScreen";

type Props = {
  appStatisticsData: AppStatisticsData;
};

const BoxComponent = ({
  boxStyle,
  title,
  totalCount,
  androidCount,
  iosCount,
}: any) => {
  return (
    <div className="app-count-flex-row">
      <div className={`count-box ${boxStyle}`}>
        <p className="app-count-title-text">{title}</p>
        <span className="app-count-text">{totalCount}</span>
      </div>
      <div className="count-box">
        <img src={playstoreLogo} alt="playstore logo" className="icon-logo" />
        <span className="count-text bold">{androidCount}</span>
      </div>
      <div className="count-box box-border-radius">
        <img src={appleLogo} alt="apple logo" className="icon-logo" />
        <span className="count-text bold">{iosCount}</span>
      </div>
    </div>
  );
};

const AppCountComponent = ({ appStatisticsData }: Props) => {
  return (
    <div className="count-container">
      <BoxComponent
        boxStyle="blue"
        title="TOTAL"
        totalCount={appStatisticsData?.overall?.total}
        androidCount={appStatisticsData?.overall?.android}
        iosCount={appStatisticsData?.overall?.ios}
      />
      <BoxComponent
        boxStyle="green"
        title="REGISTERED"
        totalCount={appStatisticsData?.registered?.total}
        androidCount={appStatisticsData?.registered?.android}
        iosCount={appStatisticsData?.registered?.ios}
      />
      <BoxComponent
        boxStyle="grey"
        title="UNREGISTERED"
        totalCount={appStatisticsData?.unregistered?.total}
        androidCount={appStatisticsData?.unregistered?.android}
        iosCount={appStatisticsData?.unregistered?.ios}
      />
    </div>
  );
};

export default AppCountComponent;
