import React from 'react';
import "./DeveloperPortal.scss";
import { developerPortalBanner, developerPortalAndroidIcon, developerPortalIosIcon, developerPortalReactNativeIcon, developerPortalXamarinIcon } from '../../assets';

const DeveloperPortal = () => {
    const onAndoridClick = () => {
        console.log("Android clicked");
    }
    const onIosClick = () => {
        console.log("iOS clicked");
    }
    const onReactNativeClick = () => {
        console.log("React Native clicked");
    }
    const onXamarinClick = () => {
        console.log("Xamarin clicked");
    }
    return (
        <div className="developer-portal-container">
            <div className="banner-container">
                <img className="banner-image" src={developerPortalBanner} alt="Developer Portal Banner" />
            </div>
            <div className="content-container">
                <p className="description">
                    A Mobile developer portal is a centralized online platform that provides resources, tools, documentation, and support for developers to develop applications with ease and adhere to defined approaches. This portal consists of documentation, templates, libraries, and sample applications to expedite the development process and maintain security standards.
                </p>
                <div className="images-grid">
                    <img src={developerPortalAndroidIcon} alt="Resource 1" onClick={onAndoridClick} />
                    <img src={developerPortalIosIcon} alt="Resource 2" onClick={onIosClick} />
                    <img src={developerPortalReactNativeIcon} alt="Resource 3" onClick={onReactNativeClick} />
                    <img src={developerPortalXamarinIcon} alt="Resource 4" onClick={onXamarinClick} />
                </div>
            </div>
        </div>
    );
};
export default DeveloperPortal;