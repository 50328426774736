import React, { useState } from "react";
import { playstoreLogo, appleLogo } from "../../assets/index";
import "./FiltersTabComponent.scss";

type fitersTabComponentProps = {
  filter: any;
  setFilterSelection: any;
};

const FiltersTabComponent = ({
  filter,
  setFilterSelection,
}: fitersTabComponentProps) => {
  const getImage = (platformType: string) => {
    if (platformType === "android") {
      return playstoreLogo;
    } else {
      return appleLogo;
    }
  };
  return (
    <div className="filter-tab-container">
      <div className="horizontal-line" />
      <div className="filter-component-heading">{filter.title}</div>
      <div className="display-row filter-options">
        {filter?.["filter-options"]?.map((value: any, index: any) => {
          if (value["showFilter"]) {
            return (
              <button
                key={index}
                className={`filter-capsule-button ${
                  value["is-selected"] && "selected-filter"
                }`}
                onClick={() =>
                  setFilterSelection(
                    !value["is-selected"],
                    filter.slug,
                    value.slug
                  )
                }
              >
                {filter.slug === "platform" && (
                  <img
                    src={getImage(value.slug)}
                    alt="icon"
                    className="capsule-icon"
                  />
                )}
                <div>{value.title}</div>
              </button>
            );
          }
        })}
      </div>
    </div>
  );
};

export default FiltersTabComponent;
