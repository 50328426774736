import {
  POST_CONTACT_US_API_INITIATED,
  POST_CONTACT_US_API_SUCCEED,
  POST_CONTACT_US_API_FAILED,
} from "../constants/contactUsConstants";

export const postContactUsApiInitiated = () => ({
  type: POST_CONTACT_US_API_INITIATED,
});

export const postContactUsApiSucceed = (payload: any) => ({
  type: POST_CONTACT_US_API_SUCCEED,
  payload: payload,
});

export const postContactUsApiFailed = (payload: any) => ({
  type: POST_CONTACT_US_API_FAILED,
  payload: payload,
});
