import React, { useEffect, useState } from "react";
import "./ContactUsScreen.scss";
import { initiateApiCall } from "../../api";
import { AxiosError, AxiosResponse } from "axios";
import {
  ErrorPopup,
  ProcessingDialog,
  SuccessPopup,
  InputField,
} from "../../components";
import { checkFieldEmpty, clearCacheAndCookies } from "../../utils/Utils";
import { useAuth } from "../../routes/useAuth";
import { useNavigate } from "react-router-dom";
import { bannerImage } from "../../assets";
import {
  postContactUsApiFailed,
  postContactUsApiInitiated,
  postContactUsApiSucceed,
} from "../../stateManagement/actions/contactUsActions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ErrorResponse } from "../../interfaces/common/ErrorResponse";

interface ContactUsApiResponse {
  code: number;
  message: string;
  result: object;
  error: string;
}

interface Field {
  value: string;
  validationError: string;
  required: boolean;
}

interface contactUsInterface {
  [key: string]: Field;
}

const ContactUsScreen = () => {
  const { setAuthToken }: any = useAuth();
  const { apiCallInitiated, apiResponse, apiCallFailed } = useSelector(
    (state: any) => state.contactUsReducers,
    shallowEqual
  );
  const [buttonSelected, setButtonSelected] = useState("Feedback");
  const [isSuccessPopupVisible, setIsSuccessPopupVisible] = useState(false);
  const [isErrorPopupVisible, setIsErrorPopupVisible] = useState(false);
  const arr = [
    {
      id: "Feedback",
      name: "Feedback",
    },
    {
      id: "Report An Issue",
      name: "Report a bug",
    },
    {
      id: "Feature Request",
      name: "Request a feature",
    },
    {
      id: "Others",
      name: "Other query",
    },
  ];

  const dispatch = useDispatch();

  const [dataObject, setDataObject] = useState<contactUsInterface>({
    "feedback-type": { value: "FEEDBACK", validationError: "", required: true },
    "feedback-title": { value: "", validationError: "", required: true },
    "feedback-desc": { value: "", validationError: "", required: true },
    "feedback-given-by": { value: "", validationError: "", required: true },
  });
  const navigate = useNavigate();

  const handleButtonClick = (value: string) => {
    setButtonSelected(value);
    setDataObject({
      ...dataObject,
      "feedback-type": {
        value: value.replace(/ /g, "_").toUpperCase(),
        validationError: "",
        required: true,
      },
    });
  };

  const onFieldBlur = (e: { target: { value: string } }, field: string) => {
    setDataObject({
      ...dataObject,
      [field]: {
        ...dataObject[field],
        value: e.target.value.trim(),
        validationError: "",
      },
    });
  };

  const handleCloseAPIError = () => {
    setIsErrorPopupVisible(false);
  };

  const handleCloseSuccessPopup = () => {
    setIsSuccessPopupVisible(false);
  };

  const onFieldValueChange = (value: string, field: string) => {
    setDataObject({
      ...dataObject,
      [field]: { ...dataObject[field], value: value, validationError: "" },
    });
  };

  useEffect(() => {
    setDataObject({
      ...dataObject,
      "feedback-given-by": {
        value: localStorage.getItem("user_email") || "",
        validationError: "",
        required: true,
      },
    });
  }, []);

  const validateData = async () => {
    const updatedDataObject = { ...dataObject };
    let isInvalidData = false;

    for (const fieldKey in updatedDataObject) {
      const field = updatedDataObject[fieldKey];
      if (field.required) {
        const validationError = checkFieldEmpty(field.value);

        if (!isInvalidData && validationError !== "") isInvalidData = true;
        updatedDataObject[fieldKey] = {
          ...updatedDataObject[fieldKey],
          validationError,
        };
      }
    }
    setDataObject(updatedDataObject);
    return isInvalidData;
  };

  const handleOnSubmit = async () => {
    const isInvalidData = await validateData();

    if (isInvalidData) return;

    try {
      dispatch(postContactUsApiInitiated());
      const response: AxiosResponse<ContactUsApiResponse> =
        await initiateApiCall(
          "post",
          `${process.env.REACT_APP_BASE_URL}` + `/platform/feedback`,
          {
            "feedback-type": dataObject["feedback-type"]["value"],
            "feedback-title": dataObject["feedback-title"]["value"],
            "feedback-desc": dataObject["feedback-desc"]["value"],
            "feedback-given-by": dataObject["feedback-given-by"]["value"],
          },
          {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            apikey: `${process.env.REACT_APP_API_KEY}`,
          }
        );
      if (response.data.code === 201) {
        dispatch(postContactUsApiSucceed(response.data));
        setIsSuccessPopupVisible(true);
        setDataObject({
          ...dataObject,
          "feedback-title": {
            value: "",
            validationError: "",
            required: true,
          },
          "feedback-desc": {
            value: "",
            validationError: "",
            required: true,
          },
        });
      }
    } catch (error: any) {
      if (error instanceof AxiosError) {
        const axiosError = error as AxiosError;
        if (axiosError.response?.status === 401) {
          clearCacheAndCookies();
          setAuthToken("");
          navigate("/");
        } else if (axiosError.response) {
          dispatch(
            postContactUsApiFailed(axiosError.response.data as ErrorResponse)
          );
          setIsErrorPopupVisible(true);
        } else {
          dispatch(
            postContactUsApiFailed({
              code: 500,
              message: "Internal Server Error",
              result: null,
              error: "Internal Server Error",
            })
          );
          setIsErrorPopupVisible(true);
        }
      }
    }
  };

  return (
    <div className="main-container">
      {apiCallInitiated && <ProcessingDialog message="Processing..." />}
      <img src={bannerImage} alt="Banner" className="contact-us-banner" />
      <div className="contact-us-heading heading-container bold">
        Contact Us
      </div>
      <div className="form-container">
        <div className="contact-us-form form">
          <div className="contact-us-form-padding form-padding">
            <div className="contact-us-container">
              <div className="contact-query-options-wrapper">
                {arr.map((item: any, index: any) => (
                  <div
                    key={index}
                    className={`contact-query-type ${
                      buttonSelected === item.id && "active-button"
                    }`}
                    onClick={() => handleButtonClick(item.id)}
                  >
                    {item.name}
                  </div>
                ))}
              </div>
              <div className="line-bottom"></div>
              <div className="contact-us-textarea-wrapper">
                <InputField
                  title="Title"
                  placeholder="Title"
                  styleType=""
                  className="contact-us-title-input"
                  value={dataObject["feedback-title"]["value"]}
                  onChange={(value: string) => {
                    onFieldValueChange(value, "feedback-title");
                  }}
                  onBlur={(e: { target: { value: string } }) =>
                    onFieldBlur(e, "feedback-title")
                  }
                  validationError={dataObject["feedback-title"].validationError}
                  required
                />
                <InputField
                  title="Description"
                  placeholder="Description"
                  className="contact-us-textarea"
                  styleType=""
                  value={dataObject["feedback-desc"]["value"]}
                  type="textArea"
                  onChange={(value: string) => {
                    onFieldValueChange(value, "feedback-desc");
                  }}
                  onBlur={(e: { target: { value: string } }) =>
                    onFieldBlur(e, "feedback-desc")
                  }
                  validationError={dataObject["feedback-desc"].validationError}
                  required
                />
              </div>
            </div>
            <div className="submit-button-wrapper">
              <button
                className="submit-button submit-button-contact-us"
                onClick={handleOnSubmit}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
      {isSuccessPopupVisible && (
        <SuccessPopup
          boldMessage="Feedback received!"
          message={apiResponse.message}
          onClose={handleCloseSuccessPopup}
        />
      )}
      {isErrorPopupVisible && (
        <ErrorPopup
          boldMessage={apiCallFailed?.message}
          message={apiCallFailed?.error}
          onClose={handleCloseAPIError}
        />
      )}
    </div>
  );
};

export default ContactUsScreen;
