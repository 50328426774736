import React from "react";
import "./AppCardComponentNew.scss";
import { appleLogo, playstoreLogo, fullStar } from "../../assets";
import { AppData } from "../../screens/dashboardScreen/DashboardScreen";
import { useNavigate } from "react-router-dom";
import FallbackImage from "../appImage/FallBackImage";

type Props = {
  index: number;
  appData: any;
  isDashboard: boolean;
};

const AppCardComponent = ({ appData, index, isDashboard }: Props) => {
  const iconMap: { [key: string]: string } = {
    ios: appleLogo,
    android: playstoreLogo,
  };

  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate("/registerApp", {
      state: {
        packageName: appData["package-name"],
        platformName: appData["platform-name"],
        from: "dashboard",
        action: "register",
      },
    });
  };

  return (
    <div className="app-card">
      <div className="app-card-content">
        <div className="app-card-icon" key={index}>
          <FallbackImage
            src={appData["store-app-logo"]}
            fallbackSrc={appData["app-logo"]}
            alt={`${appData["store-app-name"]} logo`}
          />
        </div>
        <div className="app-card-details">
          <div
            onClick={() =>
              navigate("/appDetails", {
                state: {
                  packageName: appData["package-name"],
                  platformName: appData["platform-name"],
                },
              })
            }
          >
            <div className="app-card-header">
              <span className="dashboard-app-name">
                {appData["store-app-name"]}
              </span>
              <img
                src={iconMap[appData["platform-name"]]}
                alt="Store Icon"
                className="store-icon"
              />
            </div>
            <div className="app-card-text">
              {isDashboard ? (
                appData["app-account-holder"] ? (
                  appData["app-account-holder"]
                ) : (
                  <span>&nbsp;</span>
                )
              ) : (
                appData["account-provider"]
              )}
            </div>
          </div>
          <div className="app-card-footer">
            <div className="app-card-footer-details">
              {appData["avg-rating"] > 0 ? (
                <div className="app-rating">
                  <span>{appData["avg-rating"]}</span>
                  <img src={fullStar} alt="Star Icon" className="star-icon" />
                </div>
              ) : (
                <div className="app-rating" style={{ visibility: "hidden" }}>
                  <span>{appData["avg-rating"]}</span>
                  <img src={fullStar} alt="Star Icon" className="star-icon" />
                </div>
              )}

              {isDashboard
                ? appData["app-status"] && (
                    <div
                      className={`app-status-text ${
                        appData["app-status"]["status"].toLocaleLowerCase() ===
                          "in production" ||
                        appData["app-status"]["status"].toLocaleLowerCase() ===
                          "ready for distribution"
                          ? "text-dark"
                          : ""
                      }`}
                    >
                      {appData["app-status"]["status"]}
                    </div>
                  )
                : appData["app-status"] && (
                    <div className="app-status-text text-dark">
                      {appData["app-status"]}
                    </div>
                  )}
            </div>
            {!appData["platform-registered"] &&
            isDashboard &&
            (appData["app-status"]["status"].toLocaleLowerCase() ===
              "in production" ||
              appData["app-status"]["status"].toLocaleLowerCase() ===
                "ready for distribution") ? (
              <button
                className="app-register-button"
                onClick={handleButtonClick}
              >
                Register
              </button>
            ) : (
              <button
                className="app-register-button"
                onClick={handleButtonClick}
                style={{ visibility: "hidden" }}
              >
                Register
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppCardComponent;
