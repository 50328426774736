import React from "react";
import { check } from "../../../../assets/index";
import "../AppAnalyticsByLbScreen.scss";

const SubTable = ({
  businessDirName = "Default BD Name",
  lineOfBusinessInfo,
  tableMatrix = [],
  countOfAppsPerLB = [],
}: any) => {
  const lineOfBusinessInfoArray = Array.from(lineOfBusinessInfo);

  return (
    <div className="sub-table">
      <div className="sub-table-header-container">
        <h4 className="sub-table-header-container">{businessDirName}</h4>
      </div>
      <table>
        <tr>
          {/* {Object.entries(countOfAppsPerLB).map(
            ([lbName, lbCount]: [any, any]) => (
              <th
                key={`${businessDirName}_${lbName}`}
                className="column-header"
              >
                <div className="buisness-line-text">
                  <div>{lbName}</div>
                  <div className="text-grey"> ({lbCount}) </div>
                </div>
              </th>
            )
          )} */}
          {lineOfBusinessInfoArray.map(([lbName, lbAppList]: any) => (
            <th key={`${businessDirName}_${lbName}`} className="column-header">
              <div className="buisness-line-text">
                <div>{lbName}</div>
                <div className="text-grey"> ({lbAppList.length}) </div>
              </div>
            </th>
          ))}
        </tr>

        <tbody>
          {tableMatrix.map((appList: any) => (
            <tr key={tableMatrix.lbName}>
              {appList.map((isAppPresent: any, index: any) => (
                <td
                  key={`${tableMatrix.lbName}_${index}`}
                  className={`data-cell ${
                    isAppPresent ? "app-present" : "app-absent"
                  }`}
                >
                  {isAppPresent ? <img src={check} alt="check" /> : ""}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SubTable;
